import * as React from "react"
import axios from "axios"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageHeader from "../../components/pageheader"
import DevSingle from "../../components/devsingle"
import { Button } from "../../components/buttons"
import * as styles from "../../styles/developments.module.css"
import {
    contactForm,
    formRow,
    withPrefix,
} from "../../styles/contact.module.css"
import { extractCookie, deleteUtm, submitUtm } from "../../utils/handle-utm"
import { extractSrcFromIframe } from "../../utils/utils"

export const handleSubmit = (event, data) => {
    event.preventDefault()
    const form = new FormData(event.target)
    const formData = Object.fromEntries(form)
    const query = JSON.stringify({
        query: `mutation FormMoreInfo(
                $email: String,
                $name: String,
                $phone: String,
                $propertyName: String,
                $utm: String) {
              submit2FormGetMoreInfo(
                input: {
                    clientMutationId: "",
                    email:$email,
                    fullName: $name,
                    phoneNumber: $phone,
                    propertyName: $propertyName,
                    utm: $utm
                }
              ) {
                message
                status
              }
            }`,
        variables: {
            ...formData,
            phone: "62" + formData?.phone,
            propertyName: data?.title,
            utm: extractCookie("utm_params=")
                ? JSON.stringify(extractCookie("utm_params="))
                : null,
        },
    })
    console.log(query)

    const config = {
        method: "POST",
        url: process.env.WP_URL,
        headers: {
            "Content-Type": "application/json",
        },
        data: query,
    }

    axios(config)
        .then(async function (response) {
            console.log(JSON.stringify(response.data))
            await submitUtm({
                fullname: formData.name,
                email: formData.email,
                mobile: formData?.phone,
                project_name: data?.title,
                projects_code: data?.projectCustomfields?.projectsCode,
                cluster_code: data?.projectCustomfields?.clusterCode,
                lead_source_name: data?.projectCustomfields?.leadSourceName,
                web: "kota-wisata.com",
            })
            alert("Data anda berhasil dimasukan. Terimakasih!")
            window.location.reload()
        })
        .catch(function (error) {
            console.log(error)
        })
}

export const MoreInfoForm = ({ handleSubmit }) => {
    return (
        <div className={`mx-0 ${styles.formWrap}`}>
            <form onSubmit={handleSubmit} className={contactForm}>
                <div className={formRow}>
                    <div>
                        <input
                            required
                            name="name"
                            type="text"
                            placeholder="Enter your name"
                        />
                    </div>
                </div>
                <div className={formRow}>
                    <div>
                        <input
                            required
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                        />
                    </div>
                </div>
                <div className={formRow}>
                    <div>
                        <div className={withPrefix}>
                            <span>+62</span>
                            <input
                                required
                                name="phone"
                                type="number"
                                min="0"
                                placeholder="812345xxxx"
                            />
                        </div>
                    </div>
                </div>
                <div className={formRow}>
                    <div className="text-right">
                        <Button>Submit</Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

const DevelopmentsTypeMoreInfoPage = ({ pageContext }) => {
    const data = pageContext?.data
    let prev = undefined
    let next = undefined

    if (data?.nearby.length > 1) {
        data?.nearby.forEach((item, key) => {
            if (key === 0) {
                prev = {
                    link: `${pageContext?.basicPath}/${item?.slug}`,
                    label: item?.name,
                    bgImage: item?.image,
                }
            } else {
                next = {
                    link: `${pageContext?.basicPath}/${item?.slug}`,
                    label: item?.name,
                    bgImage: item?.image,
                }
            }
        })
    }

    return (
        <Layout isTestimonialsHidden activeMenuId={3}>
            <Seo title={data?.title} />
            {data?.blocks.map((item, i) => {
                switch (item?.name) {
                    case "acf/cover-main":
                        const coverMain = item?.coverMain
                        return (
                            <PageHeader
                                key={i}
                                title={coverMain?.title}
                                subTitle={coverMain?.text}
                                image={coverMain?.img?.mediaItemUrl}
                                breadcrumbs={[
                                    {
                                        label: `Developments / ${pageContext?.propTypes?.name}`,
                                        link: pageContext?.basicPath,
                                    },
                                    { label: coverMain?.title, link: null },
                                ]}
                            />
                        )
                    case "core/shortcode":
                        if (item?.attributes?.text === "lead-form") {
                            const embedMap = data?.blocks.find(
                                item => item.name == "core/html"
                            )?.originalContent
                            return (
                                <DevSingle
                                    key={i}
                                    title={data?.title}
                                    mapSrc={embedMap ? extractSrcFromIframe(embedMap) : null}
                                    activePage="Get more info"
                                    pageLinks={pageContext?.pageSideLink}
                                    prev={prev}
                                    next={next}
                                >
                                    <h2 className="mx-4 lg:mx-0 font-serif text-4xl mb-8">
                                        Get More Information
                                    </h2>
                                    <div className="mx-4 lg:mx-0 mb-8">
                                        <p>
                                            Kota Wisata will never sell or share
                                            your information with a third party
                                        </p>
                                        <MoreInfoForm
                                            handleSubmit={e =>
                                                handleSubmit(e, data)
                                            }
                                        />
                                    </div>
                                </DevSingle>
                            )
                        }
                }
            })}
        </Layout>
    )
}

export default DevelopmentsTypeMoreInfoPage
